














































import Vue from "vue";
import { Company } from "@/core/models";
import { Action, Getter } from "vuex-class";
import { EmailsInput } from "@/components/common";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({ components: { EmailsInput } })
export default class EditCompanyName extends Vue {
  @Prop({ default: () => false }) disabled!: boolean;
  @Getter("company/company") company!: Company;
  @Getter("company/editing") loading!: boolean;
  @Action("company/editName") editName!: Function;
  @Action("displaySnackbar") displaySnackbar!: Function;

  name = "";
  editDialog = false;
  editCancel() {
    this.editDialog = false;
  }
  editConfirm() {
    const { ID } = this.company;
    const data = { companyId: ID, newName: this.name };
    this.editName(data);
    this.editDialog = false;
  }

  @Watch("loading", { immediate: true })
  companyLoaded() {
    if (this.loading) return;
    this.name = this.company.Name;
  }
}
